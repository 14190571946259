var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-table",
    [
      _c(
        "template",
        { slot: "body" },
        [
          _c(
            "gov-table-row",
            [
              _c("gov-table-header", { attrs: { top: "", scope: "row" } }, [
                _vm._v("Organisation name")
              ]),
              _c("gov-table-cell", [_vm._v(_vm._s(_vm.organisation.name))])
            ],
            1
          ),
          _c(
            "gov-table-row",
            [
              _c("gov-table-header", { attrs: { top: "", scope: "row" } }, [
                _vm._v("Description")
              ]),
              _c("gov-table-cell", {
                domProps: {
                  innerHTML: _vm._s(_vm.toHtml(_vm.organisation.description))
                }
              })
            ],
            1
          ),
          _c(
            "gov-table-row",
            [
              _c("gov-table-header", { attrs: { top: "", scope: "row" } }, [
                _vm._v("Website Address")
              ]),
              _c("gov-table-cell", { attrs: { break: "" } }, [
                _vm._v(_vm._s(_vm.organisation.url))
              ])
            ],
            1
          ),
          _c(
            "gov-table-row",
            [
              _c("gov-table-header", { attrs: { top: "", scope: "row" } }, [
                _vm._v("Phone number")
              ]),
              _c("gov-table-cell", [
                _vm._v(_vm._s(_vm.organisation.phone || "-"))
              ])
            ],
            1
          ),
          _c(
            "gov-table-row",
            [
              _c("gov-table-header", { attrs: { top: "", scope: "row" } }, [
                _vm._v("Email")
              ]),
              _c("gov-table-cell", [
                _vm._v(_vm._s(_vm.organisation.email || "-"))
              ])
            ],
            1
          ),
          _c(
            "gov-table-row",
            [
              _c("gov-table-header", { attrs: { top: "", scope: "row" } }, [
                _vm._v("Logo")
              ]),
              _c(
                "gov-table-cell",
                [
                  _vm.organisation.image
                    ? _c("ck-image", {
                        attrs: { "file-id": _vm.organisation.image.id }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "gov-table-row",
            [
              _c("gov-table-header", { attrs: { scope: "row", top: "" } }, [
                _vm._v("Social links")
              ]),
              _c(
                "gov-table-cell",
                { attrs: { break: "" } },
                [
                  _c(
                    "gov-list",
                    [
                      _vm._l(_vm.organisation.social_medias, function(
                        socialMedia,
                        index
                      ) {
                        return _c("li", { key: index }, [
                          _vm._v(
                            "\n            (" +
                              _vm._s(
                                _vm.humanReadableSocialMedia(socialMedia.type)
                              ) +
                              ")\n            " +
                              _vm._s(socialMedia.url) +
                              "\n          "
                          )
                        ])
                      }),
                      _vm.organisation.social_medias.length === 0
                        ? _c("li", [_vm._v("-")])
                        : _vm._e()
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "gov-table-row",
            [
              _c("gov-table-header", { attrs: { scope: "row", top: "" } }, [
                _vm._v("Taxonomies")
              ]),
              _c(
                "gov-table-cell",
                { attrs: { break: "" } },
                _vm._l(_vm.taxonomies, function(rootTaxonomy) {
                  return _c(
                    "gov-grid-row",
                    { key: rootTaxonomy.id },
                    [
                      _c(
                        "gov-grid-column",
                        { attrs: { width: "two-thirds" } },
                        [
                          _c("gov-heading", { attrs: { size: "m" } }, [
                            _vm._v(_vm._s(rootTaxonomy.name))
                          ]),
                          _c("ck-taxonomy-list", {
                            attrs: {
                              taxonomies: rootTaxonomy.children,
                              filteredTaxonomyIds: _vm.organisationTaxonomyIds
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }